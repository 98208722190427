import { useState, useRef, useEffect } from "react";
import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  TabbedShowLayout,
  Tab,
  Resource,
  Show,
  DateField,
  Filter,
  useNotify,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  DateInput,
  FunctionField,
  useRefresh,
  BooleanField,
  BooleanInput,
  Labeled,
  Loading,
  Datagrid } from "react-admin";
import Toggle from "../components/toggle/toggle";
import { Button } from "@material-ui/core";
import { hostname } from "../providers/api";
import { useTokenOps } from "../components/TokenContext";
import {EmptyValues} from "../components/EmptyValues"
import { handleRender } from "../utils/utils";
import axios from "axios";
import { FUNCTIONAL_UNIT, NAMES, REFERENCE_TYPES, _STATUS } from "../consts";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <TextInput label="test2" source="name" defaultValue="test2" />
    <DateInput label="Creation" source="createdAt" />
  </Filter>
);

const ProjectsList = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [state, setState] = useState("ready");
  const tokenOps = useTokenOps();
  const updateViewProject = (id, isActive) => {
    const data = JSON.stringify({ isActive: !isActive });
    setState("loading");
    fetch(hostname + "/projects/editActiveProject/" + id, {
      method: "PATCH",
      body: data,
      headers: {
        "Content-type": "application/json",
        authorization: "Bearer " + tokenOps.account?.accessToken,
      },
    }).then((response) => {
      setState("done");
      if (response.status < 400) {
        refresh();
        notify("Updated successfully", "success");
      } else {
        notify(`Error: ${response.statusText}`, "warning");
      }
    });
  };

  const handleVisibility = (e) => {
    e.stopPropagation();
  };

  return (
    <List {...props} filters={Filters(props)} bulkActionButtons={<Toggle />}>
      <div style={{ marginTop: "15px" }}>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="id" />
          <TextField source="version" />
          <ReferenceField
            label="Target origin"
            source="targetOriginId"
            reference="origins"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Benchmark project"
            source="benchmarkProjectId"
            reference="projects"
          >
            <TextField source="name" />
          </ReferenceField>

          <FunctionField
            label="Retail packaging (primary)"
            render={
              record =>
              record.primaryPackaging.length > 0
              ? handleRender(record.primaryPackaging)
              : (<EmptyValues />)
            }
          />
          <FunctionField
            label="Warehouse packaging (secondary)"
            render={
              record =>
              record.secondaryPackaging.length > 0
              ? handleRender(record.secondaryPackaging)
              : (<EmptyValues />)
            }
          />
          <FunctionField
            label="Shipping packaging (tertiary)"
            render={
              record =>
              record.tertiaryPackaging.length > 0
              ? handleRender(record.tertiaryPackaging)
              : (<EmptyValues />)
            }
          />

          <TextField source="referenceType" label="References type" />
          <TextField source="functionalUnit" label="Functional unit" />

          <DateField source="type" />
          {/* <DateField source="lastExcelUpload" label="Excel update date" /> */}
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <FunctionField
            onClick={handleVisibility}
            label="Project visibility"
            source="isActive"
            render={(item) => (
              <Toggle
                checked={item.isActive}
                onChange={() => updateViewProject(item.id, item.isActive)}
                className="ml-2"
              />
            )}
          />
          <TextField source="dataCollectionURL" label="Data collection url" required={false} />
          <TextField source="folderURL" label="Folder url" required={false} />
          <TextField source="status" label="Status" />
          <DateField source="closingDate" required={false} />

          <BooleanField source="supplyMapDigitalization" label="Supply map digitization"/>
          <BooleanField source="originWarehouseKm" label="Km from origin to warehouse"/>
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const CreateProject = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="version" />
        <TextInput source="excelDownloadLink" required={false} />
        <ReferenceInput
          label="Target origin"
          source="targetOriginId"
          reference="origins"
        >
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Benchmark project"
          source="benchmarkProjectId"
          reference="projects"
        >
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput
          label="1r packaging"
          source="primaryPackaging"
          reference="process"
        >
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput
          label="2nd packaging"
          source="secondaryPackaging"
          reference="process"
        >
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput
          label="3th packaging"
          source="tertiaryPackaging"
          reference="process"
        >
          <SelectInput source="name" />
        </ReferenceInput>
        <SelectInput source="referenceType" choices={REFERENCE_TYPES} label="References type" />
        <SelectInput source="functionalUnit" choices={FUNCTIONAL_UNIT} label="Functional unit" />

        <DateField source="type" />
        <BooleanInput source="supplyMapDigitalization" defaultValue={true} />

        <BooleanInput label="Km from origin to warehouse" source="originWarehouseKm" defaultValue={true} />

        <Labeled label="Data collection"></Labeled>
        <TextInput source="dataCollectionURL" label="Data collection url" required={false} />
        <TextInput source="folderURL" label="Folder url" required={false} />
        <SelectInput source="status" choices={_STATUS} label="Status" />
        <DateInput source="closingDate" required={false} />
      </SimpleForm>
    </Create>
  );
};

const EditProjects = (props) => {
  const tokenOps = useTokenOps();
  const [packagingIdFilter, setPackagingIdFilter] = useState("");
  const [visibilityPrimary, setVisibilityPrimary] = useState({ 0: false, 1: false, 2: false })
  const [visibilitySecondary, setVisibilitySecondary] = useState({ 0: false, 1: false, 2: false })
  const [visibilityTertiary, setVisibilityTertiary] = useState({ 0: false, 1: false, 2: false })

  const handleFocus = (type, index) => {
    switch(type) {
      case "primaryPackaging": setVisibilityPrimary({...visibilityPrimary, [index]: true})
      break
      case "secondaryPackaging": setVisibilitySecondary({...visibilitySecondary, [index]: true})
      break
      case "tertiaryPackaging": setVisibilityTertiary({...visibilityTertiary, [index]: true})
      break
      default: {}
    }
  }

  const getPackagingId = () => {
    axios.get(hostname + "/lcaCategory", {
      headers: {
        "Content-type": "application/json",
        authorization: "Bearer " + tokenOps.account?.accessToken,
      },
    })
    .then(({data}) => {
      let valueIdPackaging = data.find(d => d.name === "Packaging");
      if (valueIdPackaging) setPackagingIdFilter(valueIdPackaging.id);
    })
    .catch(error => console.log(error))
  }

  useEffect(() => {
    getPackagingId();
  }, [])

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="excelDownloadLink" required={false} />
        <ReferenceInput
          label="Target origin"
          source="targetOriginId"
          reference="origins"
        >
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Benchmark project"
          source="benchmarkProjectId"
          reference="projects"
        >
          <SelectInput source="name" />
        </ReferenceInput>

        <Labeled label="Data collection"></Labeled>
        <TextInput source="dataCollectionURL" label="Data collection url" required={false} />
        <TextInput source="folderURL" label="Folder url" required={false} />
        <SelectInput source="status" choices={_STATUS} label="Status" />
        <DateInput source="closingDate" required={false} />

        <Labeled label="Type of packaging">
          <div style={{ width: "max-content", display: "flex", flexDirection: "row", gap: 10 }}>
            {
              NAMES.map((item, index) => {
                const validator = item.key === 1 ? visibilityPrimary : item.key === 2 ? visibilitySecondary : visibilityTertiary;

                return(
                  <div key={item.key}>
                    <span className={props.view} style={{ minWidth: 160 }}>{(item.name)}</span>
                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                      {
                        validator[0]
                        ? <ReferenceInput
                            label={`1r packaging`}
                            source={`${item.source}[0].id`}
                            reference="process"
                            key={index}
                            filter={{ categoryId: packagingIdFilter }}
                          >
                            <SelectInput source="name" />
                          </ReferenceInput>
                        : <TextInput label="1r packaging" source={`${item.source}[0].name`} onFocus={() => handleFocus(item.source, 0)} />
                      } {
                        validator[1]
                        ? <ReferenceInput
                            label={`2nd packaging`}
                            source={`${item.source}[1].id`}
                            reference="process"
                            key={index + 1}
                            filter={{ categoryId: packagingIdFilter }}
                          >
                            <SelectInput source="name" />
                          </ReferenceInput>
                        : <TextInput label="2nd packaging" source={`${item.source}[1].name`} onFocus={() => handleFocus(item.source, 1)} />
                      } {
                        validator[2]
                        ? <ReferenceInput
                            label={`3th packaging`}
                            source={`${item.source}[2].id`}
                            reference="process"
                            key={index + 2}
                            filter={{ categoryId: packagingIdFilter }}
                          >
                            <SelectInput source="name" />
                          </ReferenceInput>
                        : <TextInput label="3th packaging" source={`${item.source}[2].name`} onFocus={() => handleFocus(item.source, 2)} />
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </Labeled>
        <SelectInput source="referenceType" choices={REFERENCE_TYPES} label="References type" />
        <SelectInput source="functionalUnit" choices={FUNCTIONAL_UNIT} label="Functional unit" />
        <DateField source="type" />
        <TextInput source="version" />
        <BooleanInput source="supplyMapDigitalization" />

        <BooleanInput label="Km from origin to warehouse" source="originWarehouseKm" />
      </SimpleForm>
    </Edit>
  );
};

export const ShowProjects = (props) => {
  const ref = useRef();
  const [wholeExcelErrors, setWholeExcelErrors] = useState(undefined);
  const notify = useNotify();
  const [state, setState] = useState("ready");
  const tokenOps = useTokenOps();

  const upload = (action) => () => {
    var data = new FormData();
    data.append("file", ref.current.files[0]);
    setState("loading");
    fetch(`${hostname}/projects/${props.id}/${action}`, {
      method: "POST",
      body: data,
      headers: {
        authorization: "Bearer " + tokenOps.account?.accessToken,
      },
    })
    .then((response) => {
      if (response.status < 400) {
        const message =
          action === "uploadTraceability"
          ? { message: "Excel uploaded and processing", type: 'info' }
          : { message: "Excel uploaded successfully", type: 'success' };

        notify(message.message, message.type);
      } else {
        notify(`Error: ${response.message}`, "warning");
        if (action === "uploadTraceability") {
          setWholeExcelErrors(response);
        }
      }
    })
    .catch(error => {
      notify(`Error: ${error.message}`, "warning");
    })
    .finally(() => {
      setState("done");
    });
  };

  const remove = (action) => () => {
    const METHOD = action === "deleteTraceability" ? "POST" : "DELETE";
    setState("loading");
    fetch(`${hostname}/projects/${props.id}/${action}`, {
      method: METHOD,
      headers: {
        authorization: "Bearer " + tokenOps.account?.accessToken,
      },
    }).then((response) => {
      setState("done");
      if (response.status < 400) {
        notify("Excel deleted successfully", "success");
      } else {
        notify(`Error: ${response.statusText}`, "warning");
      }
    });
  }

  const showErrors = () => {
    if (wholeExcelErrors) {
      if (typeof wholeExcelErrors === "object") {
        const message = `${wholeExcelErrors["message"] ?? 'An error occurred'} ${wholeExcelErrors['table'] ? `in table: "${wholeExcelErrors['table']}"` : ''}`;
        return (
          <div>{message}</div>
        );
      }

      return wholeExcelErrors.map((error) => <div key={error.msg} >{error.msg}</div>);
    }
  }

  return (
    <>
      <LoadingModal state={state} />
      <Show {...props}>
        <TabbedShowLayout>
          <Tab label="summary">
            <TextField source="name" />
            <TextField source="id" />
            <ReferenceField
              label="Target origin"
              source="targetOriginId"
              reference="origins"
            >
              <TextField source="name" />
            </ReferenceField>

            <TextField source="dataCollectionURL" label="Data collection url" required={false} />
            <TextField source="folderURL" label="Folder url" required={false} />
            <TextField source="status" label="Status" />
            <DateField source="closingDate" required={false} />

            <ReferenceField
              label="Benchmark project"
              source="benchmarkProjectId"
              reference="projects"
            >
              <TextField source="name" />
            </ReferenceField>

            <FunctionField
              label="Retail packaging (primary)"
              render={
                record =>
                record.primaryPackaging.length > 0
                ? handleRender(record.primaryPackaging)
                : (<EmptyValues />)
              }
            />
            <FunctionField
              label="Warehouse packaging (secondary)"
              render={
                record =>
                record.secondaryPackaging.length > 0
                ? handleRender(record.secondaryPackaging)
                : (<EmptyValues />)
              }
            />
            <FunctionField
              label="Shipping packaging (tertiary)"
              render={
                record =>
                record.tertiaryPackaging.length > 0
                ? handleRender(record.tertiaryPackaging)
                : (<EmptyValues />)
              }
            />

            <TextField source="referenceType" label="References type" />
            <TextField source="functionalUnit" label="Functional unit" />

            <DateField source="type" />
            <TextField source="version" />
            {/* <DateField source="lastExcelUpload" label="Excel update date" /> */}

            <BooleanField source="supplyMapDigitalization" label="Supply map digitization"/>
            <BooleanField source="originWarehouseKm" label="Km from origin to warehouse"/>

            <DateField source="createdAt" label="Creation date" />
            <DateField source="updatedAt" label="Update date" />
          </Tab>
          <Tab label="upload data">
            <input type="file" ref={ref} />
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: "10px", marginBottom: "10px" }}
              onClick={upload("uploadExcel")}
              disabled={state === "state"}
              component="label"
            >
              Upload data
            </Button>
          </Tab>
          <Tab label="upload traceability">
            <input type="file" ref={ref} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                color="primary"
                variant="contained"
                style={{ marginTop: "10px", marginBottom: "10px" }}
                onClick={upload("uploadTraceability")}
                disabled={state === "state"}
                component="label"
              >
                Upload traceability
              </Button>
              <Button
                style={{
                  color: "white",
                  backgroundColor: "red",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={remove("deleteTraceability")}
                component="label"
              >
                Delete
              </Button>
            </div>
            {showErrors()}
          </Tab>
          <Tab label="upload ecom refs">
            <input type="file" ref={ref} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                color="primary"
                variant="contained"
                style={{ marginTop: "10px", marginBottom: "10px" }}
                onClick={upload("uploadEcomExcel")}
                disabled={state === "state"}
                component="label"
              >
                Upload ecommerce references
              </Button>
              <Button
                  style={{
                    color: "white",
                    backgroundColor: "red",
                    marginTop: "10px",
                    marginBottom: "10px"
                  }}
                  onClick={remove("deleteEcommerceReference")}
                  component="label"
                >
                  Delete
                </Button>
            </div>
          </Tab>
          <Tab label="upload CEI">
          <input type="file" ref={ref} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                color="primary"
                variant="contained"
                style={{ marginTop: "10px", marginBottom: "10px" }}
                onClick={upload("uploadCEI")}
                disabled={state === "state"}
                component="label"
              >
                Upload CEI
              </Button>
            </div>
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};

const LoadingModal = ({state}) => {
  return (
    <div className={`${state === 'loading' ? 'project-upload-files' : 'hide-loading'}`}>
      <Loading loadingPrimary={"Uploading"} loadingSecondary={"Wait for uploading files"} />
    </div>
  )
}

const projectsResource = () => (
  <Resource
    name="projects"
    options={{ label: "Projects", menuGroup: "Projects" }}
    list={ProjectsList}
    create={CreateProject}
    edit={EditProjects}
    show={ShowProjects}
  />
);
export default projectsResource;
