import {
    Create,
    Edit,
    List,
    Resource,
    Show,
    SimpleForm,
    SelectInput,
    TextField,
    ReferenceInput,
    Datagrid,
    ReferenceField,
    DeleteButton,
    useDataProvider,
    Loading,
    Error,
    BooleanInput,
    useGetList,
    useCreate,
    useRedirect,
    useNotify,
    useDeleteMany,
    useRefresh,
} from "react-admin";
import React, { useEffect, useState } from "react";

import {Filters} from "./BasicResource";

const AccountFeaturesList = (props) => {
    return (
        <List {...props} filters={Filters(props)}>
            <div style={{marginTop: '15px'}}>
                <Datagrid rowClick="show">
                    <ReferenceField label="Account Features" source="featureId" reference="features">
                        <TextField source="name" />
                    </ReferenceField>
                    <DeleteButton/>
                </Datagrid>
            </div>
        </List>
    );
};

const ShowAccountFeatures = (props) => {
    return (
        <Show {...props}>
            <SimpleForm>
                <ReferenceField label="Account Features" source="featureId" reference="features">
                    <TextField source="name" />
                </ReferenceField>
            </SimpleForm>
        </Show>
    );
};

const CreateAccountFeatures = (props) => {
    const refresh = useRefresh();
    const [create] = useCreate();
    const [deleteMany] = useDeleteMany();
    const dataProvider = useDataProvider();
    const [accountFeatures, setAccountFeatures] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const { data: features, loading: loadingFeatures, error: errorFeatures } = useGetList(
        'features',
        { page: 1, perPage: 10 },
        { field: 'id', order: 'ASC' }
    );

    const notify = useNotify();
    const redirect = useRedirect();

    const validateFeatureInAccountFeature = (featureId) => {
        return accountFeatures.some(({featureId: id}) => id === featureId);
    }

    const addFeatures = async (featuresToAdd) => {
        const createPromises = featuresToAdd?.forEach(async (id) => {
            return create('accountFeatures', { featureId: id })
        });

        if (createPromises && createPromises.length > 0) {
            await Promise.all(createPromises);
        }
    }


    const handleSubmit = async (values) => {
        try {
            const idFeaturesToAdd = [];
            const idFeaturesToRemove = [];
            Object.entries(values).map(value => value[1] ? idFeaturesToAdd.push(value[0]) : idFeaturesToRemove.push(value[0]))

            const featuresToAdd = idFeaturesToAdd?.filter(id => !accountFeatures.some(({featureId}) => id === featureId));

            const featuresToRemove = accountFeatures.filter(({featureId}) => idFeaturesToRemove?.some(id => featureId === id));
            if (featuresToRemove.length > 0) {
                await deleteMany('accountFeatures', featuresToRemove.map(({id}) => id))
                await addFeatures(featuresToAdd)
            } else {
                addFeatures(featuresToAdd)
            }

            // Display a success notification
            notify('Record updated successfully');
            // Redirect the user to a different page after successful update
            redirect('/accountFeatures');
            refresh();

            setTimeout(() => refresh(), 1000)
        } catch (error) {
            // Handle any errors that occur during the update process
            console.error('Error updating record:', error);
            notify('Error: Unable to update record', 'error');
        }
    };

    useEffect(() => {
        let isMounted = true;

        const fetchAccountFeatures = async () => {
            dataProvider.getList('accountFeatures', { pagination: { page: 1, perPage: 10 }, sort: { field: 'id', order: 'ASC' } })
                .then(({ data }) => {
                    if (isMounted) {
                        setAccountFeatures(data);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                });
        }

        fetchAccountFeatures();

        return () => {
            isMounted = false;
        }
    }, [dataProvider]);

    if (loadingFeatures || loading) return <Loading />;
    if (errorFeatures || error) return <Error />;
    if (!features) return null;

    return (
            <Create {...props}>
                <SimpleForm save={handleSubmit}>
                    {
                        Object.values(features).map(({id, key, name}) => (
                            <BooleanInput
                                key={id}
                                source={id}
                                label={name}
                                className="account-feature-checkbox"
                                defaultValue={validateFeatureInAccountFeature(id)}
                            />
                        ))
                    }
                </SimpleForm>
            </Create>
    );
};

const EditAccountFeatures = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <ReferenceInput label="Account Feature" source="featureId" reference="features">
                    <SelectInput source="name" disabled={true} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};

const accountFeaturesResource = () => <Resource
    name="accountFeatures"
    options={{label: 'Account features', menuGroup: 'Accounts'}}
    list={AccountFeaturesList}
    edit={EditAccountFeatures}
    create={CreateAccountFeatures}
    show={ShowAccountFeatures}/>;

export default accountFeaturesResource;

