import {
    List,
    Create,
    Edit,
    SimpleForm,
    TextInput,
    TextField,
    EditButton,
    Resource,
    Show,
    Filter,
    Datagrid,
    SelectInput,
    DateField
} from "react-admin";

const SECTION = [
    {id: 'Documents', name: 'Documents'},
    {id: 'Resources', name: 'Resources'},
]

const CATEGORY = [
  {id: 'Learn', name: 'Learn'},
  {id: 'Improve', name: 'Improve'},
  {id: 'Measure', name: 'Measure'},
  {id: 'Comply', name: 'Comply'},
]

  const Filters = (props) => (
    <Filter {...props}>
      <TextInput label="Search" source="title" alwaysOn />
      <TextInput label="Section" source="section" />
      <TextInput label="Category" source="category" />
    </Filter>
  );

  const LibraryList = (props) => {
    return (
      <List {...props} filters={Filters(props)}>
        <div style={{marginTop: '15px'}}>
          <Datagrid rowClick="show">
            <TextField source="title" label="Title" />
            <TextField source="section" label="Section" />
            <TextField source="cover" label="Cover URL" />
            <TextField source="category" label="Category" />
            <TextField source="url" label="Link URL" />
            <DateField source="createdAt" label="Creation date" />
            <EditButton />
          </Datagrid>
        </div>
      </List>
    );
  };

  const ShowLibrary = (props) => {
    return (
      <Show {...props}>
        <SimpleForm>
            <TextField source="title" label="Title" />
            <TextField source="section" label="Section" />
            <TextField source="cover" label="Cover URL" />
            <TextField source="category" label="Category" />
            <TextField source="url" label="Link URL" />
            <DateField source="createdAt" label="Creation date" />
            <EditButton />
        </SimpleForm>
      </Show>
    );
  };

  const CreateLibrary = (props) => {
    return (
      <Create {...props}>
        <SimpleForm>
            <TextInput source="title" label="Title" />
            <SelectInput source="section" label="Section" choices={SECTION} />
            <TextInput source="cover" label="Cover URL" />
            <SelectInput source="category" label="Category" choices={CATEGORY} />
            <TextInput source="url" label="Link URL" />
        </SimpleForm>
      </Create>
    );
  };

  const EditLibrary = (props) => {
      return (
          <Edit {...props}>
            <SimpleForm>
                <TextInput source="title" label="Title" />
                <SelectInput source="section" label="Section" choices={SECTION} />
                <TextInput source="cover" label="Cover URL" />
                <SelectInput source="category" label="Category" choices={CATEGORY} />
                <TextInput source="url" label="Link URL" />
            </SimpleForm>
          </Edit>
    );
  };



  const libraryResource = () => <Resource
    name="library"
    options={{ label: 'Library', menuGroup: 'Accounts'}}
    list={LibraryList}
    create={CreateLibrary}
    edit={EditLibrary}
    show={ShowLibrary} />;

  export default libraryResource;