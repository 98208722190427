import {
    List,
    Create,
    Edit,
    SimpleForm,
    TextInput,
    TextField,
    EditButton,
    Resource,
    ReferenceInput,
    Show,
    Filter,
    AutocompleteInput,
    Datagrid } from "react-admin";

import { useEffect, useState } from "react";
import { useForm } from "react-final-form";

  const Filters = (props) => (
    <Filter {...props}>
      <TextInput label="Search" source="article.name" alwaysOn />
      <ReferenceInput label="Reference" source="article.reference" reference="articles">
        <TextInput source="name" />
      </ReferenceInput>
      <TextInput label="Ecom reference" source="ecomReference" />
      <TextInput label="Color" source="article.color" />
    </Filter>
  );

  const EntityList = (props) => {
    return (
      <List {...props} filters={Filters(props)}>
        <div style={{marginTop: '15px'}}>
          <Datagrid rowClick="show">
            <TextField source="article.name" label="Article Name" />
            <TextField source="id" label="Id" />
            <TextField source="article.reference" label="Reference" />
            <TextField source="ecomReference" label="Ecom reference" />
            <TextField source="article.color" label="Color" />
            <TextField source="size" label="Size" />
            <EditButton />
          </Datagrid>
        </div>
      </List>
    );
  };

  const ShowEntity = (props) => {
    return (
      <Show {...props}>
        <SimpleForm>
            <TextField source="article.name" />
            <TextField source="id" label="Id" />
            <TextField source="article.reference" label="Reference" />
            <TextField source="ecomReference" label="Ecom reference" />
            <TextField source="article.color" label="Color" />
            <TextField source="size" label="Size" />
            <EditButton />
        </SimpleForm>
      </Show>
    );
  };

  const CustomReferenceTextInput = ({parameter, name, ...rest}) => {
    const form = useForm();
    const { choices } = rest;

    useEffect(() => {
      if (choices.length === 1) {
        form.change(parameter, choices[0][parameter]);
      }
    },[choices])

    return <TextInput source={parameter} label={name} required={true} disabled {...rest} />
  }

  const CreateEntity = (props) => {
    const [idSelected, setIdSelected] = useState("")

    const handleChange = id => setIdSelected(id)

    return (
      <Create {...props}>
        <SimpleForm>
          <ReferenceInput
            label="Article"
            source="articleId"
            reference="articles"
            onChange={handleChange}
            filterToQuery={searchText => ({ name: `%${searchText}%` })}
            perPage={100}
          >
              <AutocompleteInput
                optionText="name"
                required={true}
              />
          </ReferenceInput>
          <ReferenceInput label="Reference" source="reference" reference="articles" filter={{ id: idSelected }}>
            <CustomReferenceTextInput name={"Reference"} parameter={'reference'} />
          </ReferenceInput>
          <TextInput source="ecomReference" label="Ecom reference" required={true} />
          <TextInput source="size" label="Size" />
          <ReferenceInput className="ecommerce-reference-project-id-hidde" label="Project id" source="projectId" reference="articles" filter={{ id: idSelected }}>
            <CustomReferenceTextInput name={"Project id"} parameter={'projectId'} />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    );
  };

  const EditEntity = (props) => {
    const [idSelected, setIdSelected] = useState("");
    const [showAutocomplete, setShowAutocomplete] = useState(false);

    const handleChange = id => setIdSelected(id);
    const handleFocus = () => setShowAutocomplete(true);

    return (
      <Edit {...props}>
        <SimpleForm>
          {
            !showAutocomplete
            ? <TextInput source="article.name" label="Article" onFocus={handleFocus} />
            : <ReferenceInput
                label="Article"
                source="article.id"
                reference="articles"
                onChange={handleChange}
                filterToQuery={searchText => ({ name: searchText })}
                perPage={100}
              >
                <AutocompleteInput
                  optionText="name"
                  required={true}

                />
              </ReferenceInput>
          }
          <ReferenceInput label="Reference" source="reference" reference="articles" filter={{ id: idSelected }}>
            <CustomReferenceTextInput name={"Reference"} parameter={'reference'} />
          </ReferenceInput>
          <TextInput source="ecomReference" label="Ecom reference" required={true} />
          <TextInput source="size" label="Size" />
        </SimpleForm>
      </Edit>
    );
  };



  const entityResource = () => <Resource
    name="ecom-reference-article"
    options={{ label: 'Ecommerce reference', menuGroup: 'Articles'}}
    list={EntityList}
    create={CreateEntity}
    edit={EditEntity}
    show={ShowEntity} />;

  export default entityResource;