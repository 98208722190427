import {
    List,
    Create,
    Edit,
    SimpleForm,
    TextInput,
    TextField,
    EditButton,
    DateField,
    Resource,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    Filter,
    AutocompleteInput,
    Datagrid } from "react-admin";
import { validateNumericInput } from "../utils/validators/validate-numeric";

  const Filters = (props) => (
    <Filter {...props}>
      <TextInput label="Search" source="article.name" alwaysOn />
      <ReferenceInput label="Process" source="processId" reference="process" filterToQuery={searchText => ({ name: searchText })}>
          <AutocompleteInput optionText="name"  />
      </ReferenceInput>
      <ReferenceInput label="Category" source="process.categoryId" reference="lcaCategory" filterToQuery={searchText => ({ name: searchText })} >
          <AutocompleteInput optionText="name"  />
      </ReferenceInput>
      <ReferenceInput label="Origin" source="originId" reference="origins" filterToQuery={searchText => ({ name: searchText })} >
          <AutocompleteInput optionText="name"  />
      </ReferenceInput>
      <ReferenceInput label="Transport" source="supplier.transportId" reference="transport" filterToQuery={searchText => ({ name: searchText })} >
          <AutocompleteInput optionText="name"  />
      </ReferenceInput>
    </Filter>
  );

  const ArticlePackagingList = (props) => {
    return (
      <List {...props} filters={Filters(props)}>
        <div style={{marginTop: '15px'}}>
          <Datagrid rowClick="show">
            <ReferenceField label="Article" source="articleId" reference="articles">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Packaging name" source="retailPackagingId" reference="retailPackaging">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="share" />
            <ReferenceField label="Type (online/offline)" source="retailGroupId" reference="retailGroup">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Project" source="projectId" reference="projects">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="createdAt" label="Creation date" />
            <DateField source="updatedAt" label="Update date" />
            <EditButton />
          </Datagrid>
        </div>
      </List>
    );
  };

  const ShowArticlePackaging = (props) => {
    return (
      <Show {...props}>
        <SimpleForm>
            <ReferenceField label="Article" source="articleId" reference="articles">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Packaging name" source="retailPackagingId" reference="retailPackaging">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="share" />
            <ReferenceField label="Type (online/offline)" source="retailGroupId" reference="retailGroup">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Project" source="projectId" reference="projects">
                <TextField source="name" />
            </ReferenceField>
            <DateField source="createdAt" label="Creation date" />
            <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </SimpleForm>
      </Show>
    );
  };

  const CreateArticlePackaging = (props) => {
    return (
      <Create {...props}>
        <SimpleForm>
          <ReferenceInput label="Article" source="articleId" reference="articles">
            <SelectInput optionText="name" required={true} />
          </ReferenceInput>
          <ReferenceInput label="Packaging name" source="retailPackagingId" reference="retailPackaging">
            <SelectInput optionText="name" required={true} />
          </ReferenceInput>
          <TextInput source="share" validate={validateNumericInput} />
          <ReferenceInput label="Type (online/offline)" source="retailGroupId" reference="retailGroup">
            <SelectInput optionText="name" required={true} />
          </ReferenceInput>
          <ReferenceInput label="Project" source="projectId" reference="projects">
            <SelectInput optionText="name" required={true} />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    );
  };

  const EditArticlePackaging = (props) => {
    return (
      <Edit {...props}>
        <SimpleForm>
            <ReferenceInput label="Article" source="articleId" reference="articles">
                <SelectInput optionText="name" required={true} />
            </ReferenceInput>
            <ReferenceInput label="Packaging name" source="retailPackagingId" reference="retailPackaging">
                <SelectInput optionText="name" required={true} />
            </ReferenceInput>
            <TextInput source="share" validate={validateNumericInput} />
            <ReferenceInput label="Type (online/offline)" source="retailGroupId" reference="retailGroup">
                <SelectInput optionText="name" required={true} />
            </ReferenceInput>
            <ReferenceInput label="Project" source="projectId" reference="projects">
                <SelectInput optionText="name" required={true} />
            </ReferenceInput>
        </SimpleForm>
      </Edit>
    );
  };



  const articlePackagingResource = () => <Resource
    name="articlePackaging"
    options={{ label: 'Article packaging', menuGroup: 'Articles'}}
    list={ArticlePackagingList}
    create={CreateArticlePackaging}
    edit={EditArticlePackaging}
    show={ShowArticlePackaging} />;

  export default articlePackagingResource;
