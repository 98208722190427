export const handleRender = (data, key = "name") => {
    const value = data.map(item => (item[key]))
    const result = value.join(", ")
    return result;
}

export const removeDuplicates = (arr, key = "name") => {
    const value = arr.map(item => (item[key]))
    const result = [];

    value.map(item => !result.includes(item) && result.push(item))
    return result.sort().join(", ")
}

export const handleComposition = list => {
    const arrayResult = []
    list.forEach(element => {
        arrayResult.push(`${formatNumberThreeDigits(element.amount * 100)}% ${element.processName}`)
    });
    const result = arrayResult.join(", ")

    if (result.split(",").every((val, i, arr) => ((val === arr[0]) || (val === ' ')))) return "--"
    return result;
}

function formatNumberThreeDigits(x) {
	const numberFormated = new Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(x);
	return numberFormated;
}

export const validateSupplier = url => url.includes('/supplier?filter')