import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  Show,
  SimpleShowLayout,
  Resource,
  DateField,
  DateInput,
  useNotify,
  Filter, FunctionField, useRefresh, useDataProvider,
  Datagrid,
} from "react-admin";
import {Button} from "@material-ui/core";
import {hostname} from "../providers/api";
import {useTokenOps} from "../components/TokenContext";
import { JsonField, JsonInput } from "react-admin-json-view";
import {JSONValidator} from "../utils/validators/jsonvalidator";
import { useState } from "react";
import Toggle from "../components/toggle/toggle";
import { useParams } from 'react-router-dom';

const accountTokenRequest = (tokenOps, accountId) => () => {
  if (tokenOps.rootToken?.data?.accountId === accountId) {
    tokenOps.exitAccountToken();
  } else {
    const request = new Request(hostname + "/auth/switch/" + accountId, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "authorization": "Bearer " + tokenOps.root.accessToken
      }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(tokens => {
        tokenOps.setAccountToken(tokens);
      });
  };
};

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const AccountsList = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider()

  const handleVisibility = (e) => {
    e.stopPropagation();
  };

  const updateViewAccount = (id, inactive) => {

    dataProvider.update('accounts', {
      id: id,
      data: { inactive: !inactive},
      previousData: { id: id, inactive: inactive}
    })
    .then((response) => {
      if (response.statusCode && response.statusCode >= 400) {
        notify(`Error: ${response.statusText}`, "warning");
      } else {
        refresh();
        notify("Updated successfully", "success");
      }
    });
  };

  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show" >
          <TextField source="name" />
          <TextField source="id" />
          <TextField source="refUsed" label="References used" />
          <FunctionField
            label="References available"
            render={record => record.totalReferences - record.refUsed}
          />
          <TextField source="totalReferences" />
          <TextField source="historicalReference" label="Historical references" />
          <FunctionField
            onClick={handleVisibility}
            label="Account visibility"
            source="inactive"
            render={(item) => (
              <Toggle
                checked={!item.inactive}
                onChange={() => updateViewAccount(item.id, item.inactive)}
                className="ml-2"
              />
            )}
          />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="expiration" />
          <TextField source="logoUrl" label="Logo image url" />

        </Datagrid>
      </div>
    </List>
  )
};

export const AccountsShow = (props) => {
  const notify = useNotify();
  const [state, setState] = useState('ready');
  const tokenOps = useTokenOps();

  const installPresetViews = () => {
    setState('loading');

    fetch(hostname + '/views/installPresetView', {
      method: 'POST',
      body: JSON.stringify( { "accountId": tokenOps.account?.data.accountId }),
      headers: {
        "Content-Type": "application/json",
        'authorization': 'Bearer ' + tokenOps.account?.accessToken,
      }
    }).then(({status, statusText}) => {
      setState('done');
      if (status < 400) {
        notify('views installed successfully', 'success');
      } else {
        notify(`Error: ${statusText}`, 'warning')
      }
    })
  };

  const deletePresetViews = () => {
    setState('loading');

    fetch(hostname + '/views/deletePresetView', {
      method: 'POST',
      body: JSON.stringify( { "accountId": tokenOps.account?.data.accountId }),
      headers: {
        "Content-Type": "application/json",
        'authorization': 'Bearer ' + tokenOps.account?.accessToken,
      }
    }).then(({status, statusText}) => {
      setState('done');
      if (status < 400) {
        notify('views deleted successfully', 'success');
      } else {
        notify(`Error: ${statusText}`, 'warning')
      }
    })
  };

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name"/>
        <TextField source="id"/>
        <DateField source="expiration" />
        <TextField source="refUsed" label="References used" />
        <FunctionField
          label="References available"
          render={record => record.totalReferences - record.refUsed}
        />
        <TextField source="totalReferences" />
        <TextField source="historicalReference" label="Historical references" />
        <TextField source="eCommerceDomain" />
        <DateField source="createdAt" label="Creation date" />
        {tokenOps.account?.data.accountId === props.id &&
        ( <>
          <Button  color="primary"
                 variant="contained"
                 style={{ marginTop: '10px', marginBottom: '10px'}}
                 onClick={installPresetViews}
                 disabled={state === 'state'}
                 component="label">
          Install preset views
        </Button>
        <Button  color="primary"
                 variant="contained"
                 style={{ color: 'white', backgroundColor: 'red', margin: '20px'}}
                 onClick={deletePresetViews}
                 disabled={state === 'state'}
                 component="label">
          Delete preset views
        </Button>
        </>
        )
        }
        <JsonField
        source="eCommerceCustomization"
        addLabel={true}
        jsonString={false} // Set to true if the value is a string, default: false
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: true,
        }}
      />
        <TextField source="eCommerceDomain" />

        {!tokenOps.isSwitchAccount && <Button type="primary" onClick={accountTokenRequest(tokenOps, props.id)}>Switch Account</Button>}
        {tokenOps.isSwitchAccount && <Button type="primary" onClick={tokenOps.exitAccountToken}>Logout</Button> }

      </SimpleShowLayout>
    </Show>
  );
}

const CreateAccount = (props) => {
  return (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" required={true} />
      <TextInput source="logoUrl" required={false} />
      <DateInput source="expiration" required={true} />
    </SimpleForm>
  </Create>
  )
};

const EditAccount = (props) => {
  const CustomTextFieldWithButton = ({ input, source, label, ...rest }) => {
    const tokenOps = useTokenOps();
    const { id } = useParams();
    const notify = useNotify();
    const refresh = useRefresh();
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
      setLoading(true);
      const data = JSON.stringify({ refUsed: 0 });
      fetch(hostname + "/accounts/" + id, {
        method: "PATCH",
        body: data,
        headers: {
          "Content-type": "application/json",
          authorization: "Bearer " + tokenOps.account?.accessToken,
        },
      }).then((response) => {
        if (response.status < 400) {
          refresh();
          notify("Updated successfully", "success");
        } else {
          notify(`Error: ${response.statusText}`, "warning");
        }
      })
      .finally(() => setLoading(false));
    };

    return (
      <div>
          <label className="reference-used" htmlFor={source}>
            <span>{label}</span>
          </label>
          <div className="account-reset-references">
            <TextInput type="number" {...input} source={source} {...rest} label="" />
            <Button
              variant="contained"
              style={{ marginTop: '10px', marginBottom: '10px'}}
              component="label"
              onClick={handleClick}
              disabled={loading}
            >
              { loading ? 'Loading...' : 'Reset references'}
            </Button>
        </div>

      </div>
    );
  };
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="logoUrl" required={false} />
        <DateInput source="expiration" />
        <TextInput source="totalReferences" defaultValue={0} />
        <TextField source="historicalReference" label="Historical references" />
        <CustomTextFieldWithButton source="refUsed" label="References used" />
        <FunctionField
          label="References available"
          render={record => record.totalReferences - record.refUsed}
        />
        <JsonInput
        source="eCommerceCustomization"
        validate={[JSONValidator]}
        jsonString={false} // Set to true if the value is a string, default: false
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: true,
        }}
      />
        <TextInput source="eCommerceDomain" required={false} />
      </SimpleForm>
    </Edit>
  );
};

const accountsResource = () => <Resource
  name="accounts"
  options={{ label: 'Accounts', menuGroup: 'Accounts'}}
  list={AccountsList}
  create={CreateAccount}
  edit={EditAccount}
  show={AccountsShow} />;

export default accountsResource;
